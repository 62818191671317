import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import ENV_CONFIG from "../../config";
import TransactionTable from "./TransactionTable";
import InfraMarkerService from "../../api/infraMarkerService";
import Dropdown from "../common/Dropdown";
const byMyOrgTab = 'By organization'
const onMyAssetsTab = 'By asset'
const tabs = [byMyOrgTab, onMyAssetsTab];

export default function Transactions(props) {
   const [activeTab, setActiveTab] = useState(tabs[0]);
   const [transactions, setTransactions] = useState(null);
   const [transactionUrl, setTransactionUrl] = useState(null);
   const [pageSize, setPageSize] = useState(25);
   const [pageNumber, setPageNumber] = useState(1);
   const [isLoading, setIsLoading] = useState(false);
   const [urlSortSegment, setUrlSortSegment] = useState('&order_by=action_datetime&order=desc');
   const [urlFilterSegment, setUrlFilterSegment] = useState('');
   const [urlPageSegment, setUrlPageSegment] = useState('&page_size=25');
   const [urlOrgFilterTypeSegment, setUrlOrgFilterTypeSegment] = useState('');

   const [filterAssetName, setFilterAssetName] = useState(false);
   const [filterAssetNameValue, setFilterAssetNameValue] = useState('');
   const [filterAssetOwnerOrOrg, setFilterAssetOwnerOrOrg] = useState(false);
   const [filterAssetOwnerOrOrgValue, setFilterAssetOwnerOrOrgValue] = useState('');
   const [filterAction, setFilterAction] = useState(false);
   const [filterActionValue, setFilterActionValue] = useState('');
   const [filterDate, setFilterDate] = useState(false);
   const [filterFromDate, setFilterFromDate] = useState('');
   const [filterThroughDate, setFilterThroughDate] = useState('');
   const [filterEPC, setFilterEPC] = useState(false);
   const [filterEPCValue, setFilterEPCCValue] = useState('');
   
   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   const auth = useSelector(store => store.auth);

   useEffect(() => {
      if (transactionUrl === null) {
         fetchTransactionData(urlFilterSegment, urlSortSegment, urlPageSegment, urlOrgFilterTypeSegment);
      }
   });

   useEffect(() => {
      if (transactionUrl && auth.access_token) {
         InfraMarkerService.getTransactionData(transactionUrl, auth.access_token)
            .then(response => {
               setTransactions(response.data);
            })
            .catch(() => {
               setTransactions(null);
            });
      }
   }, [transactionUrl, auth.access_token]);

   const moveNext = () => {
      var newPageNumber = pageNumber + 1
      setPageNumber(newPageNumber);
      var urlPage = '&page_size=' + pageSize + '&page=' + newPageNumber
      setUrlPageSegment(urlPage);
      setTransactionUrl(transactions.next);
   }

   const movePrevious = () => {
      var newPageNumber = pageNumber - 1;
      setPageNumber(newPageNumber);
      var urlPage = '&page_size=' + pageSize + '&page=' + newPageNumber
      setUrlPageSegment(urlPage);
      setTransactionUrl(transactions.previous);
   }

   const getTotalPages = () => {
      return Math.ceil(transactions.count / pageSize);
   }

   const handlePageSizeChange = (e) => {
      setPageSize(e.target.value);
      setPageNumber(pageNumber);
      var urlPage = '&page_size=' + e.target.value + '&page=1'
      setUrlPageSegment(urlPage);
      fetchTransactionData(urlFilterSegment, urlSortSegment, urlPage, urlOrgFilterTypeSegment);
   }

   const handlePageNumberChange = (e) => {
      const newPageNumber = e.target.value;
      if (newPageNumber >= 1 && newPageNumber <= getTotalPages()) {
         setPageNumber(newPageNumber);
         var urlPage = '&page_size=' + pageSize + '&page=' + newPageNumber
         setUrlPageSegment(urlPage);
         fetchTransactionData(urlFilterSegment, urlSortSegment, urlPage, urlOrgFilterTypeSegment);
      }
   }

   const handleTabChange  = (tab) => {
      applyFilter(tab);
   }

   const applyFilter = (tab) => {
      let urlOrgFilterType = urlOrgFilterTypeSegment
      if (tabs.includes(tab)) {
         setActiveTab(tab);
         urlOrgFilterType = tab === onMyAssetsTab ? "&org_filter_type=owner" : "";
         setUrlOrgFilterTypeSegment(urlOrgFilterType);
      } else {
         tab = activeTab;
      }

      let tempUrlFilterSegment = '';

      if (filterAssetName) {
         tempUrlFilterSegment += `&asset_name=${filterAssetNameValue}`;
      }

      if (filterAssetOwnerOrOrg) {
         if (tab === byMyOrgTab) {
            tempUrlFilterSegment += `&asset_owner=${filterAssetOwnerOrOrgValue}`;
         } else {
            tempUrlFilterSegment += `&acting_org=${filterAssetOwnerOrOrgValue}`;
         }
      }

      if (filterAction && filterActionValue !== '') {
         switch(filterActionValue) {
            case "read-selected": tempUrlFilterSegment += `&action=read&is_selected=true`; break
            default: tempUrlFilterSegment += `&action=${filterActionValue}`; break
         }
      }

      if (filterDate) {
         if(filterFromDate !== '') {
            tempUrlFilterSegment += `&after=${filterFromDate}`;
         }
         if(filterThroughDate !== '') {
            tempUrlFilterSegment += `&before=${filterThroughDate}`;
         }
      }

      if (filterEPC) {
         tempUrlFilterSegment += `&epc=${filterEPCValue}`;
      }

      setUrlFilterSegment(tempUrlFilterSegment);
      // If we are applying a filter, we need to remove paging, as we may not have the same
      // number of pages after filtering
      setPageNumber(1)
      var pageReset = '&page_size=' + pageSize + '&page=' + 1
      setUrlPageSegment(pageReset)
      fetchTransactionData(tempUrlFilterSegment, urlSortSegment, pageReset, urlOrgFilterType);
   }

   const clearFilter = () => {
      setFilterAssetOwnerOrOrg(false);
      setFilterAssetOwnerOrOrgValue('');
      setFilterAssetName(false);
      setFilterAssetNameValue('');
      setFilterAction(false);
      setFilterActionValue('');
      setFilterDate(false);
      setFilterFromDate('');
      setFilterThroughDate('');
      setFilterEPC(false);
      setFilterEPCCValue('');
      setUrlFilterSegment('');
      fetchTransactionData('', urlSortSegment, urlPageSegment, urlOrgFilterTypeSegment);
   }

   const exportTransactions = (format) => {
      setIsLoading(true);
      const baseExportUrl = ENV_CONFIG.BASE_API_URL + '/api/v1/transactions/export?org_id=' + props.org_id; 
      var exportUrl = baseExportUrl + urlFilterSegment + urlSortSegment + urlOrgFilterTypeSegment + '&timezone=' + timeZone;
      if (format === "pdf") {
         exportUrl += "&type=pdf"
      }
      InfraMarkerService.getTransactionExport(exportUrl, auth.access_token)
      .then((response) => {
         // Create blob link to download
         const url = window.URL.createObjectURL(
            new Blob([response.data]),
         );
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute(
            'download',
            response.headers['content-disposition'].split('filename=')[1].replace(/"/g, ''),
         );

         // Append to html link element page
         document.body.appendChild(link);
         setIsLoading(false);
         // Start download
         link.click();
         // Clean up and remove the link
         link.parentNode.removeChild(link);
      })
      .catch((response) => {
         // Alert user?
         setIsLoading(false);
      });
   }

   const hasFilterSet = (e) => {
      return filterAssetName ||
         filterAssetOwnerOrOrg ||
         (filterAction && filterActionValue !== '') ||
         (filterDate && filterFromDate !== '') ||
         (filterDate && filterThroughDate !== '') ||
         filterEPCValue
   }

   const handleSort = (args) => {
      if (args.length) {
         let order = args[0].desc ? 'desc' : 'asc';
         let proposedSort = '&order_by=' + args[0].id + '&order=' + order;
         if (proposedSort !== urlSortSegment) {
            var urlSort = '&order_by=' + args[0].id + '&order=' + order
            setUrlSortSegment(urlSort);
            fetchTransactionData(urlFilterSegment, urlSort, urlPageSegment, urlOrgFilterTypeSegment);
         }
      }
   }

   const fetchTransactionData = (urlFilter, urlSort, urlPage, urlOrgFilterType) => {
      var baseUrl = ENV_CONFIG.BASE_API_URL + '/api/v1/transactions/?org_id=' + props.org_id; 
      var fullUrl = baseUrl + urlFilter + urlSort + urlPage + urlOrgFilterType;
      setTransactionUrl(fullUrl);
   }

   return( 
      (!transactions) ?
         <Fragment><h2>Transactions</h2><p>Transactions not received from backend.</p></Fragment> :
         <Fragment>
            <h3 className="org-section-header">
               Transactions <i className="bi-info-circle small trans-tooltip" role="img"></i>
            </h3>
            <Tooltip anchorSelect=".trans-tooltip">
                  Includes all transactions.
            </Tooltip>
            <div className="tab-button-group">
               {tabs.map(tab => (
                  <div className={tab === activeTab ? "tab-header-active" : "tab-header"}
                     key={tab}
                     title={tab === byMyOrgTab ? "Transactions made by this organization.":"Transactions against assets owned by this organization."}
                     onClick={() => handleTabChange(tab)}>
                     {tab}
                  </div>
               ))}
               <div className="tab-header-filler"></div>
            </div>
            <div className="tab-body-header"></div>
            <div className="tab-body">
               <div className="float-right btn-group">
                  {isLoading ?
                     <button className="btn btn-primary" disabled title="Export transactions based on the current filter and sort...">
                        <span className="spinner-border spinner-border-sm" role="status"></span>&nbsp;
                        <span>Preparing...</span>
                     </button>
                     :<>
                     <Dropdown trigger={
                        <button disabled={transactions.count===0} className="btn btn-primary" title="Export transactions based on the current filter and sort...">
                           <i className="bi-download" role="img"></i>
                           <span>Export</span>
                        </button>}
                        menu={[
                           <button disabled={transactions.count===0} onClick={exportTransactions}>
                              CSV&nbsp;<i className="bi-filetype-csv" role="img"></i>
                           </button>,
                           <button className={transactions.count > 2000 ? 'pdf-tooltip':''} disabled={transactions.count===0 || transactions.count > 2000} onClick={() => exportTransactions('pdf')}>
                              PDF&nbsp;<i className="bi-file-pdf" role="img"></i>
                           </button>
                        ]}
                     />
                     <Tooltip anchorSelect=".pdf-tooltip">Maximum 2000 records.</Tooltip></>
                  }
               </div>
               <table >
                  <thead>
                     <tr><th>Filter by:</th></tr>
                     <tr>
                        <th>
                           <label id="assetNameFilterLabel"><input type="checkbox" checked={filterAssetName} onChange={(e) => { setFilterAssetName(e.target.checked)}} />Asset Name</label> 
                        </th>
                        <th>
                           <label id="assetOwnerFilterLabel"><input type="checkbox" checked={filterAssetOwnerOrOrg} onChange={(e) => { setFilterAssetOwnerOrOrg(e.target.checked)}} />{activeTab === byMyOrgTab ? 'Asset Owner' : 'Organization'}</label>
                        </th>
                        <th>
                           <label><input type="checkbox" checked={filterAction} onChange={(e) => { setFilterAction(e.target.checked)}} />Action</label>
                        </th>
                        <th>
                           <label><input type="checkbox" checked={filterDate} onChange={(e) => { setFilterDate(e.target.checked)}} />Date</label>
                        </th>
                        <th>
                           <label id="inframarkerIdFilterLabel"><input type="checkbox" checked={filterEPC} onChange={(e) => { setFilterEPC(e.target.checked)}} />InfraMarker ID</label>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td style={{verticalAlign:'top'}}><input aria-labelledby="assetNameFilterLabel" value={filterAssetNameValue} hidden={!filterAssetName} className="search-input" type="text" onChange={(e) => setFilterAssetNameValue(e.target.value)} /></td>
                        <td style={{verticalAlign:'top'}}><input aria-labelledby="assetOwnerFilterLabel" value={filterAssetOwnerOrOrgValue} hidden={!filterAssetOwnerOrOrg} className="search-input" type="text" onChange={(e) => setFilterAssetOwnerOrOrgValue(e.target.value)} /></td>
                        <td style={{verticalAlign:'top'}}>
                           <label>
                              <select hidden={!filterAction} name="choice" onChange={(e) => setFilterActionValue(e.target.value)} defaultValue="">
                                 <option value=""></option>
                                 <option value="read">Read (all)</option>
                                 <option value="read-selected">Read (selected)</option>
                                 <option value="write-lock">Write Lock</option>
                                 <option value="write">Write</option>
                              </select>
                           </label>
                        </td>
                        <td style={{verticalAlign:'top', textAlign:"right"}}>
                           <label hidden={!filterDate}>
                              from:<input value={filterFromDate} className="search-input" name="from_date" type="date" onChange={(e) => setFilterFromDate(e.target.value)} />
                           </label>
                           <br/>
                           <label hidden={!filterDate}>
                              to:<input value={filterThroughDate} className="search-input" name="through_date" type="date" onChange={(e) => setFilterThroughDate(e.target.value)} />
                           </label>
                        </td>
                        <td style={{verticalAlign:'top'}}>
                           <input aria-labelledby="inframarkerIdFilterLabel" style={{width:'225px'}} value={filterEPCValue} hidden={!filterEPC} className="search-input" type="text" onChange={(e) => setFilterEPCCValue(e.target.value)} />
                        </td>
                        <td style={{verticalAlign:'top'}}>
                           <button hidden={!hasFilterSet()} className="btn-primary" onClick={applyFilter}>Apply Filter</button>
                        </td>
                        <td style={{verticalAlign:'top'}}>
                           <button hidden={urlFilterSegment===''} className="btn-primary" onClick={clearFilter}>Clear Filter</button>
                        </td>
                     </tr>
                  </tbody>
               </table>
                 
               <TransactionTable data={transactions.results} onSort={handleSort} isAssets={activeTab===onMyAssetsTab} />
               {transactions.count > 25 ?
               <div className="pagination">
                  <label>
                     Page size: <select onChange={(e) => handlePageSizeChange(e)} defaultValue="25">
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                     </select>
                  </label>
                  <button className="btn btn-primary" disabled={transactions?.previous === null} onClick={movePrevious}>Previous</button>
                  <label>Page <input id="pageNumber" type="number" min="1" max={getTotalPages()} value={pageNumber} onChange={(e) => handlePageNumberChange(e)}></input> of {getTotalPages()}</label>
                  <button className="btn btn-primary" disabled={transactions?.next === null} onClick={moveNext}>Next</button>
               </div> : <></>}
            </div>
         </Fragment>
   );
}
