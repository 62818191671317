import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import InfraMarkerService from '../../api/infraMarkerService';
import Loader from '../common/Loader';
import { IAppState } from '../../reducers';
import { IAuthState } from '../../reducers/auth';
import { IOrgState } from '../../reducers/organizations';
import infraMarkerService from '../../api/infraMarkerService';
import { IDwToken, IPagedResult, ISharingRequest } from '../../api/models';
import { IUser } from '../../reducers/auth';
import { DataShare } from './DataShare';
import { DataKey } from './DataKey';
import ENV_CONFIG from '../../config';

interface IDataServicesParams {
   orgState: IOrgState,
   user: IUser | null
}

const GeoServices: React.FC<IDataServicesParams> = (param: IDataServicesParams) => {
  
   const auth = useSelector<any, IAuthState>(store => store.auth);
   // Page level properties
   const [loading, setLoading] = useState(false);
   const [userHasDatasharing, setUserHasDatasharing] = useState(false);
   const [dwTokens, setDwTokens] = useState<IDwToken[]>([])
   const [geoToken, setGeoToken] = useState<IDwToken | null>(null);
   // Restrictions on shares are at the org level
   const [orgShareCount, setOrgShareCount] = useState(0);
   const [tokenDisplay, setTokenDisplay] = useState("");
   const defaultOrgFilterType = "action";
   const [geoOrgFilterType, setGeoOrgFilterType] = useState(defaultOrgFilterType);
   const [prevResults, setPrevResults] = useState<string | null>(null);
   const [nextResults, setNextResults] = useState<string | null>(null);

   const [dwSharingRequests, setDwSharingRequests] = useState<ISharingRequest[]>([])
   const [resendingId, setResendingId] = useState<string | null>(null);

   useEffect(() => {
      loadPage()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [auth, param.orgState.orgs]);

   function loadPage() {
      if (param.orgState.orgs?.some(o=>o.is_active)) {
         let hasDataSharing = param.orgState.orgs.some(o=>o.allow_datasharing);
         if (hasDataSharing) {
            setUserHasDatasharing(true);
            infraMarkerService.getDwTokens(auth.access_token!!).then((resp) => {
               if (resp?.data?.results) {
                  processTokenResult(resp.data);
                  getDataSharesRecursive(undefined)
               }
            });
         }
      }
   }

   function getDataSharesRecursive(dataShareUrl: string | undefined, dataShares: ISharingRequest[] = []) {
      // Data shares are paged, so we want to keep trying to get shares as long as there
      // is a "next" URL.  useState seems to have issues setting and getting within the 
      // context of this recursion, so we are passing shares through/into the method 
      // to get them all first, and then setting to useState once there are no "next"
      // calls left to make.
      infraMarkerService.getDataShares(auth.access_token!!, dataShareUrl).then((resp) => {
         if (resp?.data?.results) {
            resp?.data?.results.forEach(share => {
               dataShares.push(share);
            });
         }

         if (resp?.data?.next) {
            getDataSharesRecursive(resp?.data?.next, dataShares);
         } else {
            setDwSharingRequests(dataShares);
         }
      });
   }

   function getPage(pageUrl: string) {
      infraMarkerService.getDwTokenPage(pageUrl, auth.access_token!!).then((resp) => {
         if (resp?.data?.results) {
            processTokenResult(resp.data)
         }
      })
   }

   function processTokenResult(result: IPagedResult<IDwToken>) {
      if (result.results.length) {
         let token = result.results[0];
         setGeoToken(token);
         updateTokenDisplay(token);
         setDwTokens(result.results);
         setPrevResults(result.previous);
         setNextResults(result.next);
         setTimeout(() => {
            (document.getElementById(token.token + '-radio') as HTMLInputElement).click();
         }, 250);
      }
   }

   function expireToken(token: string) {
      InfraMarkerService.expireDwToken(token, auth.access_token!!)
         .then(() => {
            setLoading(false);
            let tokenToRemove = dwTokens.find(t => t.token === token)
            let tokens = dwTokens.filter(t => t.token !== token)
            // If we are paging and are down to 0 tokens, go to the previous page.
            if (prevResults !== null && tokens.length === 0) {
               getPage(prevResults)
               // If there is a next page and we deleted, refresh this page
            } else if (nextResults !== null) {
               loadPage()
            } else {
               // Otherwise just remove the token from the list
               setDwTokens(tokens)
               if (token === geoToken?.token) {
                  if (tokens.length > 0) {
                     setGeoToken(tokens[0])
                     updateTokenDisplay(tokens[0]);
                     (document.getElementById(tokens[0].token + '-radio') as HTMLInputElement).click()
                  } else {
                     setGeoToken(null);
                     setTokenDisplay("");
                  }
               }
            }
            let shares = dwSharingRequests.filter(s => s.token !== tokenToRemove?.id);
            setSharingRequestsAndOrgShareCounts(shares);
         })
         .catch(() => {
            setLoading(false);
         });
   }

   function expireSharedAccess(id: string) {
      InfraMarkerService.expireDataShare(id, auth.access_token!!).then(()=> {
         let shares = dwSharingRequests.filter(s => s.id !== id);
         setSharingRequestsAndOrgShareCounts(shares);
      });
   }

   function setSharingRequestsAndOrgShareCounts(sharingRequests: ISharingRequest[]) {
      setDwSharingRequests(sharingRequests);
      let org =  param.orgState.orgs?.find(o => o.id === geoToken?.org_id)?.id;
      let orgTokens = dwTokens.filter(t => t.org_id === org).map(t=>t.id);
      setOrgShareCount(sharingRequests.filter(s => orgTokens.includes(s.token)).length);
   }

   function resendSharedAccess(id: string) {
      setResendingId(id);
      InfraMarkerService.resendDataShare(id, auth.access_token!!).then(()=> {
         setResendingId(null);
      });
   }

   const onGeoTokenChange = (e: ChangeEvent<HTMLInputElement>) => {
      // If we have selected a token that filters by geofile, make sure we initialize org filter type to the default value
      // in case the user goes back to not filtering by geofile, as by doing that the org filter type DDL will have lost
      // it's selection
      let token = dwTokens.find(t=>t.token === e.target.value)!!;
      if (hasFilterFileSelected(token)) {
         setGeoOrgFilterType(defaultOrgFilterType);
      }
      setGeoToken(token);
      updateTokenDisplay(token);
   }

   function updateTokenDisplay(token: IDwToken) {
      let org =  param.orgState.orgs?.find(o=>o.id===token?.org_id);
      setTokenDisplay(token.name + ' (' + org?.name + ')')
   }

   const onRowClick = (radioId: string)  => {
      (document.getElementById(radioId) as HTMLInputElement).click();
   }

   function hasFilterFileSelected(token: IDwToken | undefined | null): boolean {
      let orgHasLocation = param.orgState.orgs?.find(o=>o.id === token?.org_id)?.allow_geofilter === true;
      return orgHasLocation && token?.geofilter !== null && token?.geofilter !== undefined;
   }

   function formatDate(dateToFormat: Date): string {
      let parts = dateToFormat.toString().split('T')[0].split('-');
      return parts[1] + '/' + parts[2] + '/' + parts[0];
   }

   function addSharingRequest(request: ISharingRequest) {
      let shares = [...dwSharingRequests];
      shares.push(request);
      setSharingRequestsAndOrgShareCounts(shares);
   }

   function addToken(token: IDwToken) {
      let tokens = [...dwTokens];
      tokens.push(token);
      setDwTokens(tokens);
   }

   function escapeRegExp(string: string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
   }

   function replaceAll(str: string, find: string, replace: string): string {
      return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
   }

   return (
      loading ? <Loader message='Loading...' /> :
         !userHasDatasharing ? 
         <div className='main-content'>You do not have access to data sharing.  Please <a href='#/contact-us'>contact us</a> if you would like information.</div> :
         <div className='main-content'>
            <h2 className="page-title">Data Sharing</h2>
            <p>
               This page allows you to manage data access keys that can be used to interact with our data service.  Using a data 
               access key in the customized link below, you can import your InfraMarker geospatial data in into the GIS system or 
               application of your choice and interact with the data on a map.  This system currently only supports the&nbsp;
               <a href='https://geojson.org/' target='_blank' rel='noreferrer' className='link'>GeoJSON</a> format for the Area filter and as 
               output from the data service.  GeoJSON is a format for encoding geographic data structures.
            </p>

            <div className='row'>
               <DataKey orgs={param?.orgState?.orgs} userName={param.user?.username} addToken={addToken} setLoading={setLoading} tokens={dwTokens}></DataKey>
               <div className='col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                  <h3 className="org-section-header">Manage data access keys</h3>
                  <p>Multiple keys can be created to access your organization's data.  Different keys allow you to manage separate access for separate persons or entities or area filters.  Select a key from the table below to use it in the 'Customize...' section.  Remove a key by clicking the corresponding '<i className="bi-trash" role="img"></i>' button on the right to remove access to the data for that key.</p>
                  <table className="table content-body">
                     <thead>
                        <tr>
                           <th>Key Name</th>
                           <th>Expiration</th>
                           <th>Filter(s)</th>
                           <th className='text-right'>Resend / Remove</th>
                        </tr>
                     </thead>
                     <tbody>
                        {dwTokens.map(dwToken =>
                           <React.Fragment key={dwToken.id+'fg'}>

                              <tr className='data-key-row' onClick={() => onRowClick(dwToken.token + '-radio')} key={dwToken.id+'tr'} title={'Created by ' + dwToken.created_by + ' for ' + param.orgState.orgs?.find(o => o.id === dwToken.org_id)?.name + ' ' + dwToken.token}>
                                 <td>
                                    <label>
                                       <input type='radio' id={dwToken.token + '-radio'} name='selectedToken' value={dwToken.token} onChange={onGeoTokenChange} />
                                       {dwToken.name}
                                       {!dwToken.is_geofilter_approved ? <span className='not-approved' title="Geofilter file has not been approved yet."><i className="bi bi-exclamation-circle-fill" role="img"></i></span>:<></>}
                                    </label>
                                 </td>
                                 <td>{formatDate(dwToken.expiration_date)}</td>
                                 <td>
                                    { 
                                       dwToken.geofilter_name ?
                                          <a href={ENV_CONFIG.BASE_API_URL! + dwToken.cloud_geofilter_url as unknown as string} title={"Area filter: " + dwToken.geofilter_name}><i className="bi bi-filetype-json" style={{fontSize: "1.5rem"}} role="img"></i></a>:<></>
                                    }
                                    { 
                                       dwToken.asset_filter ?
                                          <span title={"Asset filter: " + dwToken.asset_filter.replace(":&:", " '") + "'"}><i className="bi bi-funnel" style={{fontSize: "1.5rem"}} role="img"></i></span>:<></>
                                    }
                                    { 
                                       dwToken.owner_filter ?
                                          <span title={"Owner filter: " + replaceAll(dwToken.owner_filter, ':&:', ',')}><i className="bi bi-funnel" style={{fontSize: "1.5rem"}} role="img"></i></span>:<></>
                                    }
                                 </td>
                                 <td className='text-right'>
                                    <button title='Remove key' onClick={() => expireToken(dwToken.token)} className="btn btn-danger">
                                       <i className="bi-trash" role="img"></i>
                                    </button>
                                 </td>
                              </tr>
                              
                              {dwSharingRequests?.filter(s => s.token === dwToken.id).map(sharing => {
                                 return <tr key={sharing.id}>
                                    <td className='text-right' colSpan={3}>
                                       Shared with <b>{sharing.recipient}</b> on <b>{formatDate(sharing.created_on)}</b> as <b title={sharing.url}>{sharing.description}.</b>
                                       <br/>
                                       { sharing.acknowledged_on ? 'Acknowledged on ' + formatDate(sharing.acknowledged_on!) : '  Not acknowledged yet.' }
                                    </td>
                                    <td className='text-right'>
                                       <div className='btn-group'>
                                          {resendingId === sharing.id ? <div><div className="spinner-border spinner-border-sm" role="status"><span className="sr-only"></span></div> Resending...</div>:<></>}
                                          <button hidden={resendingId === sharing.id || sharing.acknowledged_on !== null} title='Resend shared access' onClick={() => resendSharedAccess(sharing.id)} className="btn btn-primary">
                                             <i style={{marginRight:"0px"}} className="bi-envelope" role="img"></i>
                                          </button>
                                          <button title='Remove shared access' onClick={() => expireSharedAccess(sharing.id)} className="btn btn-danger">
                                             <i className="bi-trash" role="img"></i>
                                          </button>
                                       </div>
                                    </td>
                                 </tr>   
                              })}
                           </React.Fragment>
                           )
                        }
                     </tbody>
                  </table>
                  {
                     prevResults !== null || nextResults !== null ?
                     <div className='horizontal-center' style={{ visibility: prevResults !== null || nextResults !== null ? "visible" : "hidden" }}>
                        <button className="btn btn-primary" disabled={prevResults == null} onClick={() => getPage(prevResults!!)}>Previous</button>&nbsp;
                        <button className="btn btn-primary" disabled={nextResults == null} onClick={() => getPage(nextResults!!)}>Next</button>
                     </div>: <></>
                  }
               </div>
            </div>
            <br />
            <DataShare geoToken={geoToken} tokenDisplay={tokenDisplay} sharingRequestCount={orgShareCount} userName={param.user?.username}
               orgHasDataSharing={param.orgState.orgs?.find(o => o.id === geoToken?.org_id)?.allow_geofilter === true} addSharingRequest={addSharingRequest}
               geoOrgFilterType={geoOrgFilterType} setGeoOrgFilterType={setGeoOrgFilterType} />
            <br />
            <div className='text-center'><em>*This service may require an additional subscription option in the future.</em></div>
         </div>
   );
}

const mapStateToProps = (state: IAppState) => ({
   orgState: state.org,
   user: state.auth.user
})
export default connect(mapStateToProps)(GeoServices);
