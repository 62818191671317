import axios from 'axios';
import { returnErrors } from './messages';

import { ActionTypes } from './types';

import ENV_CONFIG from '../config';
import { getAuthHeaders } from '../common';
import { loadOrganizations } from './organizations';

export const handleUnauthorized = () => (dispatch: any) => {
   axios.interceptors.response.use(
         (r) => { return r;},
         (error) => {
            if (error.response?.status === 401) {
               alert("Session expired. Redirecting to login.");
               dispatch({ type: ActionTypes.CLEAR_ORGS });
               dispatch({ type: ActionTypes.LOGOUT_SUCCESS });
            }
            return Promise.reject(error);
         }
      );
};

// Want to check tokens
export const loadUser = () => (dispatch: any, getState: any) => {
   // User Loading
   dispatch({ type: ActionTypes.USER_LOADING });

   const config = tokenConfig(getState);

   if (config.headers.Authorization) {
      axios
         .get(ENV_CONFIG.BASE_API_URL + '/api/v1/users/', tokenConfig(getState))
         .then((res) => {
            dispatch({
               type: ActionTypes.USER_LOADED,
               payload: res.data[0],
            });
            dispatch(loadOrganizations());
         })
         .catch((err) => {
            dispatch(returnErrors(err.response?.data, err.response?.status));
            dispatch({
               type: ActionTypes.AUTH_ERROR,
            });
         });
   }
};

// LOGIN USER
export const login = (username: string, password: string, recapValue: string) => (dispatch: any) => {

   // Body
   var formData = new FormData();
   formData.append('grant_type', 'password');
   formData.append('client_id', ENV_CONFIG.API_CLIENT_ID || "");
   formData.append('username', username);
   formData.append('password', password);
   formData.append('recap_value', recapValue);

   // Headers
   const config = {
      headers: {
         "Content-Type": "multipart/form-data",
      },
   };

   axios
      .post(ENV_CONFIG.BASE_API_URL + '/v1/oauth2/token/', formData, config)
      .then((res) => {
         dispatch({
            type: ActionTypes.LOGIN_SUCCESS,
            payload: res.data,
         });
         dispatch(loadUser());
      })
      .catch((err) => {
         dispatch(returnErrors(err.response.data, err.response.status));
         dispatch({
            type: ActionTypes.LOGIN_FAIL,
         });
      });
};

// LOGOUT USER
export const logout = () => (dispatch: any , getState: any) => {

   //invalidating the refresh_token also invalidates the access_token   
   var formData = new FormData();
   formData.append('client_id', ENV_CONFIG.API_CLIENT_ID || "");
   formData.append('token', getState().auth.refresh_token);

   axios
      .post(ENV_CONFIG.BASE_API_URL + '/v1/oauth2/revoke-token/', formData, tokenConfig(getState))
      .then(() => {
         dispatch({ type: ActionTypes.CLEAR_ORGS });
         dispatch({ type: ActionTypes.LOGOUT_SUCCESS });
      })
      .catch((err) => {
         dispatch(returnErrors(err.response.data, err.response.status));
      });
};

// Setup config with token - helper function
export const tokenConfig = (getState: any) => {
   // Get token from state
   const access_token = getState().auth.access_token;

   return getAuthHeaders(access_token);
};