import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import InfraMarkerService from "../../api/infraMarkerService";
import {License as LicenseModel} from "../../models/license";

export default function License(props) {

   const auth = useSelector(store => store.auth);
   const [license, setLicense] = useState(null);
   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const [expiringMessages, setExpiringMessages] = useState([]);

   useEffect(() => {
      if (props.org_id && auth.access_token) {
         // request licensing
         InfraMarkerService.getLicense(props.org_id, auth.access_token)
            .then(response => {
               setLicense(response.data);
            })
            .catch(() => setLicense(null));
      }
   }, [props.org_id, auth.access_token]);

   useEffect(() => {
      if (license !== null) {
         const lic = new LicenseModel(license);
         setStartDate(lic.getCurrentStartDateDisplay());
         setEndDate(lic.getCurrentEndDateDisplay());
         setExpiringMessages(lic.getExpiringMessages());
      }
   }, [license]);

   return (
      <>
         <div className={props.has_data_sharing ? "col-12 col-md-6" : "col-12"}>
            <h3 className="org-section-header">Current License</h3>
            <div className="row">
               <div className="column" style={{paddingLeft:"10px"}}>
                  {(startDate == null) ? <p>No license found.</p> :
                     <p>
                        Total Transactions Purchased: {license.total_transaction_count}<br/>
                        Remaining Transaction Count: {license.remaining_transaction_count}<br/>
                        Start Date: {startDate}<br/>
                        Expiration Date: {endDate}
                     </p>
                  }
               </div>
               {expiringMessages.length ?
                  <div className="column">
                     {expiringMessages.map(message => <div className="alert alert-danger" key={message}>{message}</div> ) }
                     <div className="alert alert-info"><em>Please contact your account rep, or contact us at 877-686-8550 or <a href="mailto:support@inframarker.com" className="link">support@inframarker.com</a> to renew.</em></div>
                  </div> : <></>
               }
            </div>
         </div>
      </>
   )
}
